import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import ImkbEndeksSenetler from "../../../util/ImkbEndeksSenetler";
import klineInterval from "./params";
import AtaYatirimSymbols from "../../../util/AtaYatirimSymbols";

//const DATA_COUNT = 30;
const COLLECTION_NAME = "NS_LINES_30";

interface IXUData {
  lines30: IData[];
  requested: boolean;
  loaded: boolean;
  lastUpdate: number;
  selectedDate: Date | null;
}

const initialState: IXUData = {
  lines30: [],
  requested: false,
  loaded: false,
  lastUpdate: 0,
  selectedDate: null
};

export function loadLines30Snapshot(firebase: any, period: number, count: number, historyDate: Date | null = null) {
  if (period < 0 || ![0, 10, 30, 99].includes(period)) {
    console.log("Invalid period for line 30. Period:", period);
    return async function thunk(dispatch: any, getState: any) {
      dispatch({
        type: SENTIMENT_ACTION.EMPTY_ACTION,
        payload: {},
      });
    };
  }
  return async function thunk(dispatch: any, getState: any) {
    historyDate = historyDate ?? new Date();
    const state = getState();
    if (new Date().getTime() - state.Lines30Snapshot.lastUpdate < 60 * 1000 && historyDate != null && historyDate == state.selectedDate) return;
    dispatch({
      type: SENTIMENT_ACTION.LINES_30_SNAPSHOT,
      payload: { requested: true, loaded: false, sentiment: [] },
    });
    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}${klineInterval}_P${period}`)
      .orderBy("dateTime", "desc")
      .where("dateTime", "<=", historyDate)
      .limit(count)
      .get().then((querySnapshot: any) => {
        var lines30Data: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          //if (lines30Data.some((x: any) => { x.Symbol == doc.data().symbol })) return;

          lines30Data.push({
            name: "lines30",
            Index: doc.data().index,
            Symbol: doc.data().symbol,
            Sentiment: doc.data().sentiment,
            Order: doc.data().order,
            ODate: doc.data().openDate,
            OPrice: doc.data().openPrice != "0" ? doc.data().openPrice : "",
            Price: doc.data().price,
            DailyChange: doc.data().dailyChange,
            Vbts: doc.data().vbts,
            First5to10: doc.data().first5to10,
            IsNewFirst5: doc.data().isNewFirst5,
            Viop: doc.data().viop,
            PerDay: doc.data().perDay,
            SentimentList: doc.data().sentimentList,
            PriceList: doc.data().priceList,
            ChangeList: doc.data().changeList,
            ReChangeList: doc.data().changeList.slice().reverse(),
            dateTime: doc.data().dateTime.toDate(),
            Time: doc.data().time ?? 0,
            UpdateTime: moment
              .unix(doc.data().dateTime.seconds)
              .format("DD.MM.yy HH:mm"),
            LineDate: doc.data().dateTime.seconds,
            Indexes: ImkbEndeksSenetler.filter(x => x.name == doc.data().symbol)[0].val
          });
        });

        // from firebase
        //lines30Data = lines30Data.filter((x: any) => { return AtaYatirimSymbols.includes(x.Symbol) });

        dispatch({
          type: SENTIMENT_ACTION.LINES_30_SNAPSHOT,
          payload: {
            lines30: lines30Data.sort((a: any, b: any) => { return a.Order - b.Order }),
            loaded: true,
            requested: true,
            lastUpdate: new Date().getTime(),
            selectedDate: historyDate
          },
        });
      });
  };
}

export default function Lines30Snapshot(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.LINES_30_SNAPSHOT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const lines30SnapshotSelector = (state: any) => state.Lines30Snapshot;
