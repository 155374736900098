import SentimentEndeksler from "./SentimentEndeksler";
const ImkbEndeksSenetler = [
    { val: [], name: "A1CAP", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUMAL,XAKUR" },
    { val: [], name: "ACSEL", ix: "XUTUM,XTUMY,XBANA,XKOBI,XTMTU,XKTUM,XKTMT,XUGRA,XUSIN,XKMYA,XSDNZ" },
    { val: [], name: "ADEL", ix: "XUTUM,XTUMY,XBANA,XUSIN,XSIST" },
    { val: [], name: "ADESE", ix: "XUTUM,XTUMY,XBANA,XKTUM,XK100,XUHIZ,XSKON" },
    { val: [], name: "ADGYO", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUMAL,XGMYO" },
    { val: [], name: "AEFES", ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XKURY,XUSRD,XTM25S,XUSIN,XGIDA,XSIST" },
    { val: [], name: "AFYON", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XTAST" },
    { val: [], name: "AGESA", ix: "XUTUM,XTUMY,XYLDZ,XUSRD,XUGRA,XUMAL,XSGRT" },
    { val: [], name: "AGHOL", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XUMAL,XHOLD,XSIST" },
    { val: [], name: "AGYO", ix: "XUTUM,XTUMY,XBANA,XUMAL,XGMYO" },
    { val: [], name: "AHGAZ", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XHARZ,XKTUM,XK100,XK050,XK030,XUGRA,XUHIZ,XELKT,XSIST" },
    { val: [], name: "AKBNK", ix: "XU100,XU050,XU030,XLBNK,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XUSRD,XSD25,XUMAL,XBANK" },
    { val: [], name: "AKCNS", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XKTUM,XK100,XSRDK,XKTMT,XUSRD,XUSIN,XTAST,XSIST" },
    { val: [], name: "AKENR", ix: "XUTUM,XTUMY,XBANA,XKURY,XUSRD,XUHIZ,XELKT,XSIST" },
    { val: [], name: "AKFGY", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUMAL,XGMYO" },
    { val: [], name: "AKFYE", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUHIZ,XELKT,XSANK" },
    { val: [], name: "AKGRT", ix: "XUTUM,XTUMY,XYLDZ,XUSRD,XUMAL,XSGRT" },
    { val: [], name: "AKMGY", ix: "XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XKURY,XUMAL,XGMYO" },
    { val: [], name: "AKSA", ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XKURY,XUSRD,XSD25,XTM25S,XUSIN,XKMYA" },
    { val: [], name: "AKSEN", ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XKURY,XUSRD,XUHIZ,XELKT,XSIST" },
    { val: [], name: "AKSGY", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XSRDK,XKURY,XUSRD,XUGRA,XUMAL,XGMYO" },
    { val: [], name: "AKSUE", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XELKT" },
    { val: [], name: "AKYHO", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST" },
    { val: [], name: "ALARK", ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XKURY,XUMAL,XHOLD,XSIST" },
    { val: [], name: "ALBRK", ix: "XU100,XLBNK,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XKURY,XUSRD,XUGRA,XUMAL,XBANK" },
    { val: [], name: "ALCAR", ix: "XUTUM,XTUMY,XBANA,XUSIN,XMESY,XSIST" },
    { val: [], name: "ALCTL", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUTEK,XTKJS,XBLSM,XSANK" },
    { val: [], name: "ALFAS", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XKTUM,XK100,XK050,XUHIZ,XELKT" },
    { val: [], name: "ALGYO", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XUGRA,XUMAL,XGMYO" },
    { val: [], name: "ALKA", ix: "XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XK100,XKTMT,XUSIN,XKAGT,XSIZM" },
    { val: [], name: "ALKIM", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XKTMT,XTM25S,XUSIN,XKMYA" },
    { val: [], name: "ALMAD", ix: "XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XMADN,XSTKR" },
    { val: [], name: "ANELE", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XINSA,XSIST" },
    { val: [], name: "ANGEN", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKOBI,XUSIN,XKMYA,XSIST" },
    { val: [], name: "ANHYT", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XUSRD,XUMAL,XSGRT" },
    { val: [], name: "ANSGR", ix: "XUTUM,XTUMY,XYLDZ,XKURY,XUSRD,XUMAL,XSGRT" },
    { val: [], name: "ARASE", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XTMTU,XKTUM,XK100,XKTMT,XUHIZ,XELKT" },
    { val: [], name: "ARCLK", ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XKURY,XUSRD,XSD25,XUGRA,XUSIN,XMESY,XSIST" },
    { val: [], name: "ARDYZ", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XKTMT,XUGRA,XUTEK,XTKJS,XBLSM,XSIST" },
    { val: [], name: "ARENA", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XKTMT,XUTEK,XTKJS,XBLSM,XSIST" },
    { val: [], name: "ARSAN", ix: "XUTUM,XTUMY,XYLDZ,XUSIN,XTEKS" },
    { val: [], name: "ARZUM", ix: "XUTUM,XTUMY,XBANA,XTMTU,XUHIZ,XTCRT,XSIST" },
    { val: [], name: "ASELS", ix: "XU100,XU050,XU030,X10XB,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XTMTU,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XKURY,XUSRD,XUTEK,XTKJS,XSANK" },
    { val: [], name: "ASGYO", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUMAL,XGMYO" },
    { val: [], name: "ASTOR", ix: "XU100,XU050,XU030,X10XB,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XHARZ,XKTUM,XK100,XK050,XK030,XUSIN,XMESY,XSANK" },
    { val: [], name: "ASUZU", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSIN,XMESY,XSKOC" },
    { val: [], name: "ATAGY", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XGMYO" },
    { val: [], name: "ATAKP", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XGIDA" },
    { val: [], name: "ATATP", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUTEK,XTKJS,XBLSM,XSIST" },
    { val: [], name: "ATEKS", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XTEKS" },
    { val: [], name: "ATLAS", ix: "XYORT" },
    { val: [], name: "AVGYO", ix: "XUTUM,XTUMY,XBANA,XUMAL,XGMYO" },
    { val: [], name: "AVHOL", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST" },
    { val: [], name: "AVOD", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XGIDA,XSIZM" },
    { val: [], name: "AVTUR", ix: "XUTUM,XTUMY,XUHIZ,XTRZM,XSIST" },
    { val: [], name: "AYCES", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XTRZM,XSIZM" },
    { val: [], name: "AYDEM", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XUGRA,XUHIZ,XELKT,XSDNZ" },
    { val: [], name: "AYEN", ix: "XUTUM,XTUMY,XYLDZ,XUHIZ,XELKT,XSKAY" },
    { val: [], name: "AYGAZ", ix: "XUTUM,XTUMY,XYLDZ,XKURY,XUSRD,XUSIN,XKMYA,XSIST" },
    { val: [], name: "AZTEK", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUTEK,XTKJS,XBLSM,XSIST" },
    { val: [], name: "BAGFS", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUSIN,XKMYA,XSBAL" },
    { val: [], name: "BAKAB", ix: "XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUSIN,XKAGT,XSIZM" },
    { val: [], name: "BANVT", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XGIDA,XSBAL" },
    { val: [], name: "BARMA", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XKAGT,XSIZM" },
    { val: [], name: "BASGZ", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XK050,XKTMT,XUMAL,XGMYO" },
    { val: [], name: "BAYRK", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XKMYA,XSIST" },
    { val: [], name: "BERA", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUMAL,XHOLD,XSKON" },
    { val: [], name: "BEYAZ", ix: "XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUHIZ,XULAS,XSBAL" },
    { val: [], name: "BFREN", ix: "XUTUM,XTUMY,XBANA,XKOBI,XTMTU,XUSIN,XMESY,XSBUR" },
    { val: [], name: "BIENY", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XTAST" },
    { val: [], name: "BIGCH", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUHIZ,XTRZM,XSIST" },
    { val: [], name: "BIMAS", ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XUSRD,XSD25,XTM25S,XUHIZ,XTCRT" },
    { val: [], name: "BIOEN", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XK050,XKURY,XUHIZ,XELKT,XSIZM" },
    { val: [], name: "BIZIM", ix: "XUTUM,XTUMY,XYLDZ,XUSRD,XUHIZ,XTCRT" },
    { val: [], name: "BJKAS", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XSPOR,XSIST" },
    { val: [], name: "BLCYT", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XTEKS,XSADA" },
    { val: [], name: "BMSCH", ix: "XUTUM,XTUMY,XBANA,XUSIN,XMANA,XSMNS" },
    { val: [], name: "BMSTL", ix: "XUTUM,XTUMY,XBANA,XHARZ,XUSIN,XMANA,XSKOC" },
    { val: [], name: "BNTAS", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XMESY,XSBAL" },
    { val: [], name: "BOBET", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XTAST,XSIST" },
    { val: [], name: "BOSSA", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XTEKS,XSADA" },
    { val: [], name: "BRISA", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XUSRD,XUSIN,XKMYA,XSKOC" },
    { val: [], name: "BRKSN", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XKMYA,XSTKR" },
    { val: [], name: "BRKVY", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUMAL" },
    { val: [], name: "BRLSM", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUHIZ,XINSA,XSIST" },
    { val: [], name: "BRSAN", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUSIN,XMANA,XSIST" },
    { val: [], name: "BRYAT", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XUMAL,XHOLD,XSIST" },
    { val: [], name: "BSOKE", ix: "XUTUM,XTUMY,XBANA,XUSIN,XTAST,XSAYD" },
    { val: [], name: "BTCIM", ix: "XUTUM,XTUMY,XYLDZ,XKURY,XUSIN,XTAST,XSIZM" },
    { val: [], name: "BUCIM", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XK050,XUSIN,XTAST,XSBUR" },
    { val: [], name: "BURCE", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XMANA,XSBUR" },
    { val: [], name: "BURVA", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XMANA,XSBUR" },
    { val: [], name: "BVSAN", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUSIN,XMESY,XSANK" },
    { val: [], name: "BYDNR", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUHIZ,XTRZM,XSIZM" },
    { val: [], name: "CANTE", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XUHIZ,XELKT" },
    { val: [], name: "CCOLA", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XKURY,XUSRD,XTM25S,XUSIN,XGIDA,XSIST" },
    { val: [], name: "CELHA", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XMANA,XSKOC" },
    { val: [], name: "CEMAS", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XMANA" },
    { val: [], name: "CEMTS", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XKTUM,XK100,XK050,XKTMT,XUSIN,XMANA,XSBUR" },
    { val: [], name: "CEOEM", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XSIST" },
    { val: [], name: "CIMSA", ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XUSRD,XSD25,XUSIN,XTAST" },
    { val: [], name: "CLEBI", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUHIZ,XULAS,XSIST" },
    { val: [], name: "CMBTN", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XTAST,XSIZM" },
    { val: [], name: "CONSE", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XKURY,XUHIZ,XELKT,XSIST" },
    { val: [], name: "COSMO", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XHOLD,XSIST" },
    { val: [], name: "CRDFA", ix: "XUTUM,XTUMY,XBANA,XKURY,XUMAL,XFINK" },
    { val: [], name: "CRFSA", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XTCRT" },
    { val: [], name: "CUSAN", ix: "XUTUM,XTUMY,XYLDZ,XUGRA,XUSIN,XMANA,XSIST" },
    { val: [], name: "CVKMD", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XMADN,XSBUR" },
    { val: [], name: "CWENE", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUHIZ,XELKT,XSANT" },
    { val: [], name: "DAGHL", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST" },
    { val: [], name: "DAGI", ix: "XUTUM,XTUMY,XBANA,XUSIN,XTEKS,XSIST" },
    { val: [], name: "DAPGM", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUHIZ,XINSA,XSIST" },
    { val: [], name: "DARDL", ix: "XUTUM,XTUMY,XBANA,XKTUM,XK100,XUSIN,XGIDA" },
    { val: [], name: "DENGE", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST" },
    { val: [], name: "DERHL", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST" },
    { val: [], name: "DERIM", ix: "XUTUM,XTUMY,XBANA,XUSIN,XTEKS,XSIST" },
    { val: [], name: "DESA", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XTEKS" },
    { val: [], name: "DESPC", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUTEK,XTKJS,XBLSM,XSIST" },
    { val: [], name: "DEVA", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XUSIN,XKMYA,XSTKR" },
    { val: [], name: "DGATE", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUGRA,XUTEK,XTKJS,XBLSM,XSIST" },
    { val: [], name: "DGGYO", ix: "XUTUM,XTUMY,XBANA,XKTUM,XKURY,XUMAL,XGMYO" },
    { val: [], name: "DGNMO", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUGRA,XUSIN,XKAGT" },
    { val: [], name: "DITAS", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XMESY" },
    { val: [], name: "DMRGD", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XGIDA,XSIST" },
    { val: [], name: "DMSAS", ix: "XUTUM,XTUMY,XBANA,XUSIN,XMANA" },
    { val: [], name: "DNISI", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XKMYA,XSIZM" },
    { val: [], name: "DOAS", ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XKURY,XUSRD,XSD25,XUHIZ,XTCRT,XSIST" },
    { val: [], name: "DOBUR", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XSIST" },
    { val: [], name: "DOCO", ix: "XUTUM,XTUMY,XBANA,XUSRD,XUHIZ,XTRZM" },
    { val: [], name: "DOGUB", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XTAST" },
    { val: [], name: "DOHOL", ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XKURY,XUSRD,XSD25,XTM25S,XUMAL,XHOLD,XSIST" },
    { val: [], name: "DOKTA", ix: "XUTUM,XTUMY,XBANA,XUSIN,XMANA,XSBUR" },
    { val: [], name: "DURDO", ix: "XUTUM,XTUMY,XBANA,XUGRA,XUSIN,XKAGT,XSIST" },
    { val: [], name: "DYOBY", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XKMYA,XSKOC" },
    { val: [], name: "DZGYO", ix: "XUTUM,XTUMY,XBANA,XUMAL,XGMYO" },
    { val: [], name: "EBEBK", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUHIZ,XTCRT" },
    { val: [], name: "ECILC", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XTM25S,XUMAL,XHOLD,XSIST" },
    { val: [], name: "ECZYT", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XTM25S,XUMAL,XHOLD,XSIST" },
    { val: [], name: "EDATA", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUTEK,XTKJS,XBLSM,XSANK" },
    { val: [], name: "EDIP", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XINSA,XSIST" },
    { val: [], name: "EGEEN", ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XK030,XKTMT,XTM25S,XUSIN,XMESY,XSIZM" },
    { val: [], name: "EGEPO", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUHIZ,XSIZM" },
    { val: [], name: "EGGUB", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XKTMT,XUSIN,XKMYA,XSIZM" },
    { val: [], name: "EGPRO", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XKMYA,XSIZM" },
    { val: [], name: "EGSER", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XUSIN,XTAST,XSIZM" },
    { val: [], name: "EKGYO", ix: "XU100,XU050,XU030,X10XB,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XTM25S,XUGRA,XUMAL,XGMYO" },
    { val: [], name: "EKSUN", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XGIDA,XSTKR" },
    { val: [], name: "ELITE", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XGIDA,XSANK" },
    { val: [], name: "EMKEL", ix: "XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XMESY,XSANK" },
    { val: [], name: "ENERY", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUHIZ,XELKT,XSIST" },
    { val: [], name: "ENJSA", ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XKURY,XUSRD,XSD25,XTM25S,XUHIZ,XELKT,XSIST" },
    { val: [], name: "ENKAI", ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XKURY,XUSRD,XSD25,XUHIZ,XINSA,XSIST" },
    { val: [], name: "ENSRI", ix: "XUTUM,XTUMY,XBANA,XHARZ,XKOBI,XUSIN,XTEKS,XSTKR" },
    { val: [], name: "EPLAS", ix: "XUTUM,XTUMY,XBANA,XUSIN,XKMYA,XSIZM" },
    { val: [], name: "ERBOS", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XUSIN,XMANA,XSKAY" },
    { val: [], name: "ERCB", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XK050,XUSIN,XMANA" },
    { val: [], name: "EREGL", ix: "XU100,XU050,XU030,X10XB,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XKURY,XUSRD,XTM25S,XUGRA,XUSIN,XMANA" },
    { val: [], name: "ERSU", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XGIDA,XSKON" },
    { val: [], name: "ESCAR", ix: "XUTUM,XTUMY,XYLDZ,XUHIZ,XSIST" },
    { val: [], name: "ESCOM", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUTEK,XTKJS,XBLSM,XSIST" },
    { val: [], name: "ESEN", ix: "XUTUM,XTUMY,XYLDZ,XUSRD,XUGRA,XUHIZ,XELKT,XSANK" },
    { val: [], name: "ETILR", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XTRZM,XSIST" },
    { val: [], name: "ETYAT", ix: "XYORT" },
    { val: [], name: "EUHOL", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST" },
    { val: [], name: "EUKYO", ix: "XYORT" },
    { val: [], name: "EUPWR", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XMESY,XSANK" },
    { val: [], name: "EUREN", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XUSIN,XKMYA" },
    { val: [], name: "EUYO", ix: "XYORT" },
    { val: [], name: "EYGYO", ix: "XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUMAL,XGMYO" },
    { val: [], name: "FADE", ix: "XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XGIDA,XSIZM" },
    { val: [], name: "FENER", ix: "XUTUM,XTUMY,XYLDZ,XUHIZ,XSPOR,XSIST" },
    { val: [], name: "FLAP", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XSIST" },
    { val: [], name: "FMIZP", ix: "XUTUM,XTUMY,XBANA,XKOBI,XTMTU,XKTUM,XKTMT,XUSIN,XMESY,XSKOC" },
    { val: [], name: "FONET", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUGRA,XUTEK,XTKJS,XBLSM,XSANK" },
    { val: [], name: "FORMT", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XMESY,XSKAY" },
    { val: [], name: "FORTE", ix: "XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUTEK,XTKJS,XBLSM,XSANK" },
    { val: [], name: "FRIGO", ix: "XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XGIDA,XSBUR" },
    { val: [], name: "FROTO", ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XKURY,XUSRD,XSD25,XTM25S,XUSIN,XMESY,XSKOC" },
    { val: [], name: "FZLGY", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUMAL,XGMYO" },
    { val: [], name: "GARAN", ix: "XU100,XU050,XU030,XLBNK,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XKURY,XUSRD,XUMAL,XBANK" },
    { val: [], name: "GARFA", ix: "XUTUM,XTUMY,XBANA,XKURY,XUMAL,XFINK" },
    { val: [], name: "GEDIK", ix: "XUTUM,XTUMY,XYLDZ,XUMAL,XAKUR" },
    { val: [], name: "GEDZA", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XKMYA" },
    { val: [], name: "GENIL", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XK050,XUGRA,XUHIZ,XTCRT,XSANK" },
    { val: [], name: "GENTS", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XKAGT" },
    { val: [], name: "GEREL", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XMESY" },
    { val: [], name: "GESAN", ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUHIZ,XINSA,XSANK" },
    { val: [], name: "GIPTA", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUSIN,XKAGT,XSANK" },
    { val: [], name: "GLBMD", ix: "XUTUM,XTUMY,XBANA,XUMAL,XAKUR" },
    { val: [], name: "GLCVY", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XTMTU,XUMAL" },
    { val: [], name: "GLRYH", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST" },
    { val: [], name: "GLYHO", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XUMAL,XHOLD,XSIST" },
    { val: [], name: "GMTAS", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XTCRT" },
    { val: [], name: "GOKNR", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUSIN,XGIDA" },
    { val: [], name: "GOLTS", ix: "XUTUM,XTUMY,XYLDZ,XUSIN,XTAST" },
    { val: [], name: "GOODY", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XKMYA,XSIST" },
    { val: [], name: "GOZDE", ix: "XUTUM,XTUMY,XYLDZ,XUMAL,XHOLD" },
    { val: [], name: "GRNYO", ix: "XYORT" },
    { val: [], name: "GRSEL", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUGRA,XUHIZ,XULAS,XSIST" },
    { val: [], name: "GRTRK", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUHIZ,XTCRT,XSIST" },
    { val: [], name: "GSDDE", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XULAS,XSIST" },
    { val: [], name: "GSDHO", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUMAL,XHOLD,XSIST" },
    { val: [], name: "GSRAY", ix: "XUTUM,XTUMY,XYLDZ,XUHIZ,XSPOR,XSIST" },
    { val: [], name: "GUBRF", ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUSIN,XKMYA,XSIST" },
    { val: [], name: "GWIND", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XSRDK,XKTMT,XKURY,XUSRD,XTM25S,XUHIZ,XELKT,XSBAL" },
    { val: [], name: "GZNMI", ix: "XUTUM,XTUMY,XBANA,XHARZ,XUHIZ,XSANT" },
    { val: [], name: "HALKB", ix: "XU100,XU050,XLBNK,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XUGRA,XUMAL,XBANK" },
    { val: [], name: "HATEK", ix: "XUTUM,XTUMY,XBANA,XUSIN,XTEKS" },
    { val: [], name: "HATSN", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XMESY" },
    { val: [], name: "HDFGS", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD" },
    { val: [], name: "HEDEF", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUMAL,XHOLD,XSIST" },
    { val: [], name: "HEKTS", ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XUSIN,XKMYA,XSKOC" },
    { val: [], name: "HKTM", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKOBI,XKTUM,XUSIN,XMESY,XSKOC" },
    { val: [], name: "HLGYO", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XKURY,XUGRA,XUMAL,XGMYO" },
    { val: [], name: "HTTBT", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUTEK,XTKJS,XBLSM,XSIST" },
    { val: [], name: "HUBVC", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XHOLD" },
    { val: [], name: "HUNER", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUHIZ,XELKT,XSIST" },
    { val: [], name: "HURGZ", ix: "XUTUM,XTUMY,XBANA,XKURY,XUHIZ,XSIST" },
    { val: [], name: "ICBCT", ix: "XUTUM,XTUMY,XBANA,XUMAL,XBANK" },
    { val: [], name: "ICUGS", ix: "XUTUM,XTUMY,XKTUM,XUMAL,XHOLD" },
    { val: [], name: "IDEAS", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XSIST" },
    { val: [], name: "IDGYO", ix: "XUTUM,XTUMY,XKTUM,XUMAL,XGMYO" },
    { val: [], name: "IEYHO", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XHOLD,XSIST" },
    { val: [], name: "IHAAS", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUHIZ,XSIST" },
    { val: [], name: "IHEVA", ix: "XUTUM,XTUMY,XBANA,XKTUM,XKURY,XUGRA,XUSIN,XMESY,XSIST" },
    { val: [], name: "IHGZT", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XSIST" },
    { val: [], name: "IHLAS", ix: "XUTUM,XTUMY,XBANA,XKURY,XUMAL,XHOLD,XSIST" },
    { val: [], name: "IHLGM", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XSIZM" },
    { val: [], name: "IHYAY", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XHOLD,XSIST" },
    { val: [], name: "IMASM", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XMESY,XSKON" },
    { val: [], name: "INDES", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XUGRA,XUTEK,XTKJS,XBLSM,XSIST" },
    { val: [], name: "INFO", ix: "XUTUM,XTUMY,XYLDZ,XUMAL,XAKUR" },
    { val: [], name: "INGRM", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUTEK,XTKJS,XBLSM,XSIST" },
    { val: [], name: "INTEM", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XTCRT,XSIST" },
    { val: [], name: "INVEO", ix: "XUTUM,XTUMY,XYLDZ,XUMAL,XHOLD,XSIST" },
    { val: [], name: "INVES", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUMAL,XHOLD,XSIST" },
    { val: [], name: "IPEKE", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUSIN,XMADN,XSANK" },
    { val: [], name: "ISATR", ix: "XUTUM,XTUMY,XUMAL,XBANK" },
    { val: [], name: "ISBTR", ix: "XUTUM,XTUMY,XUMAL,XBANK" },
    { val: [], name: "ISCTR", ix: "XU100,XU050,XU030,XLBNK,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XUSRD,XSD25,XUMAL,XBANK" },
    { val: [], name: "ISDMR", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XKURY,XUSRD,XTM25S,XUSIN,XMANA" },
    { val: [], name: "ISFIN", ix: "XUTUM,XTUMY,XYLDZ,XKURY,XUSRD,XUMAL,XFINK" },
    { val: [], name: "ISGSY", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD" },
    { val: [], name: "ISGYO", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUMAL,XGMYO" },
    { val: [], name: "ISKPL", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XKMYA,XSKOC" },
    { val: [], name: "ISMEN", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XUSRD,XTM25S,XUMAL,XAKUR" },
    { val: [], name: "ISSEN", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XTMTU,XKTUM,XK100,XKTMT,XUSIN,XTEKS,XSBAL" },
    { val: [], name: "ISYAT", ix: "XYORT" },
    { val: [], name: "ITTFH", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSKON" },
    { val: [], name: "IZENR", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUHIZ,XELKT,XSIZM" },
    { val: [], name: "IZFAS", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUGRA,XUSIN,XKMYA,XSIZM" },
    { val: [], name: "IZINV", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XSIST" },
    { val: [], name: "IZMDC", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUSIN,XMANA,XSIZM" },
    { val: [], name: "JANTS", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XKTMT,XUSIN,XMESY,XSAYD" },
    { val: [], name: "KAPLM", ix: "XUTUM,XTUMY,XBANA,XUSIN,XKAGT,XSIZM" },
    { val: [], name: "KAREL", ix: "XUTUM,XTUMY,XYLDZ,XUTEK,XTKJS,XBLSM,XSANK" },
    { val: [], name: "KARSN", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XK050,XSRDK,XUSRD,XUSIN,XMESY,XSBUR" },
    { val: [], name: "KARTN", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XUSIN,XKAGT,XSKOC" },
    { val: [], name: "KARYE", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XELKT,XSMNS" },
    { val: [], name: "KATMR", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XMESY,XSIZM" },
    { val: [], name: "KAYSE", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XGIDA,XSKAY" },
    { val: [], name: "KCAER", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XKTUM,XK100,XK050,XUSIN,XMANA,XSIZM" },
    { val: [], name: "KCHOL", ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XUSRD,XSD25,XTM25S,XUMAL,XHOLD,XSIST" },
    { val: [], name: "KERVT", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XSRDK,XUSRD,XUSIN,XGIDA,XSBUR" },
    { val: [], name: "KFEIN", ix: "XUTUM,XTUMY,XBANA,XTMTU,XKURY,XUTEK,XTKJS,XBLSM,XSIST" },
    { val: [], name: "KGYO", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUMAL,XGMYO" },
    { val: [], name: "KIMMR", ix: "XUTUM,XTUMY,XBANA,XHARZ,XUGRA,XUHIZ,XTCRT" },
    { val: [], name: "KLGYO", ix: "XUTUM,XTUMY,XYLDZ,XUMAL,XGMYO" },
    { val: [], name: "KLKIM", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XTAST,XSIST" },
    { val: [], name: "KLMSN", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XUSIN,XMESY,XSMNS" },
    { val: [], name: "KLRHO", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUMAL,XHOLD,XSIST" },
    { val: [], name: "KLSER", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XTAST" },
    { val: [], name: "KLSYN", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUGRA,XUSIN,XKAGT,XSTKR" },
    { val: [], name: "KMPUR", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XTMTU,XKTUM,XK100,XKTMT,XKURY,XUSIN,XKMYA,XSKOC" },
    { val: [], name: "KNFRT", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XGIDA,XSDNZ" },
    { val: [], name: "KONKA", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XTMTU,XKTUM,XK100,XKTMT,XUSIN,XKAGT,XSKON" },
    { val: [], name: "KONTR", ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XKURY,XUGRA,XUHIZ,XSIST" },
    { val: [], name: "KONYA", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XK050,XUSIN,XTAST,XSKON" },
    { val: [], name: "KOPOL", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XKMYA" },
    { val: [], name: "KORDS", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XUSRD,XSD25,XUSIN,XTEKS,XSKOC" },
    { val: [], name: "KOZAA", ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XUSIN,XMADN,XSANK" },
    { val: [], name: "KOZAL", ix: "XU100,XU050,XU030,X10XB,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XUGRA,XUSIN,XMADN,XSIZM" },
    { val: [], name: "KRDMA", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUGRA,XUSIN,XMANA" },
    { val: [], name: "KRDMB", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XMANA" },
    { val: [], name: "KRDMD", ix: "XU100,XU050,XU030,X10XB,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUGRA,XUSIN,XMANA" },
    { val: [], name: "KRGYO", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XGMYO" },
    { val: [], name: "KRONT", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUTEK,XTKJS,XBLSM,XSIST" },
    { val: [], name: "KRPLS", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XKMYA,XSIST" },
    { val: [], name: "KRSTL", ix: "XUTUM,XTUMY,XBANA,XKTUM,XK100,XUSIN,XGIDA,XSBAL" },
    { val: [], name: "KRTEK", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XTEKS,XSKAY" },
    { val: [], name: "KRVGD", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUGRA,XUSIN,XGIDA,XSIST" },
    { val: [], name: "KTLEV", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUMAL" },
    { val: [], name: "KTSKR", ix: "XUTUM,XTUMY,XYLDZ,XUSIN,XGIDA" },
    { val: [], name: "KUTPO", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XTAST" },
    { val: [], name: "KUYAS", ix: "XUTUM,XTUMY,XBANA,XKTUM,XK100,XUGRA,XUHIZ,XINSA,XSIST" },
    { val: [], name: "KZBGY", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XK050,XUMAL,XGMYO" },
    { val: [], name: "KZGYO", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUMAL,XGMYO" },
    { val: [], name: "LIDER", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XTMTU,XUHIZ,XSIST" },
    { val: [], name: "LIDFA", ix: "XUTUM,XTUMY,XBANA,XKURY,XUMAL,XFINK" },
    { val: [], name: "LINK", ix: "XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUTEK,XTKJS,XBLSM,XSIST" },
    { val: [], name: "LKMNH", ix: "XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUGRA,XUHIZ,XSANK" },
    { val: [], name: "LOGO", ix: "XUTUM,XTUMY,XYLDZ,XKURY,XUSRD,XUGRA,XUTEK,XTKJS,XBLSM,XSKOC" },
    { val: [], name: "LUKSK", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XTEKS,XSKAY" },
    { val: [], name: "MAALT", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XTRZM,XSANT" },
    { val: [], name: "MACKO", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUHIZ,XSIST" },
    { val: [], name: "MAGEN", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUSRD,XUHIZ,XELKT,XSANK" },
    { val: [], name: "MAKIM", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKOBI,XKTUM,XUSIN,XMESY,XSANK" },
    { val: [], name: "MAKTK", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XMESY,XSKOC" },
    { val: [], name: "MANAS", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUTEK,XTKJS,XBLSM,XSANK" },
    { val: [], name: "MARKA", ix: "XUTUM,XTUMY,XUMAL,XHOLD,XSIST" },
    { val: [], name: "MARTI", ix: "XUTUM,XTUMY,XYLDZ,XUGRA,XUHIZ,XTRZM,XSANT" },
    { val: [], name: "MAVI", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XK050,XSRDK,XUSRD,XUHIZ,XTCRT" },
    { val: [], name: "MEDTR", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XKMYA,XSIZM" },
    { val: [], name: "MEGAP", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XTEKS,XSIST" },
    { val: [], name: "MEPET", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XTCRT" },
    { val: [], name: "MERCN", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XKMYA,XSDNZ" },
    { val: [], name: "MERIT", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XTRZM,XSIST" },
    { val: [], name: "MERKO", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XGIDA,XSBUR" },
    { val: [], name: "METRO", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST" },
    { val: [], name: "METUR", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST" },
    { val: [], name: "MGROS", ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XSD25,XUHIZ,XTCRT" },
    { val: [], name: "MIATK", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUTEK,XTKJS,XBLSM,XSANK" },
    { val: [], name: "MIPAZ", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XTCRT" },
    { val: [], name: "MNDRS", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XTEKS,XSDNZ" },
    { val: [], name: "MNDTR", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XKAGT,XSIZM" },
    { val: [], name: "MOBTL", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUTEK,XTKJS,XBLSM,XSIST" },
    { val: [], name: "MPARK", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XSRDK,XUSRD,XUGRA,XUHIZ,XSIST" },
    { val: [], name: "MRGYO", ix: "XUTUM,XTUMY,XYLDZ,XUGRA,XUMAL,XGMYO" },
    { val: [], name: "MRSHL", ix: "XUTUM,XTUMY,XBANA,XUSIN,XKMYA,XSKOC" },
    { val: [], name: "MSGYO", ix: "XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUMAL,XGMYO" },
    { val: [], name: "MTRKS", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XKTMT,XUGRA,XUTEK,XTKJS,XBLSM,XSIST" },
    { val: [], name: "MTRYO", ix: "XYORT" },
    { val: [], name: "MZHLD", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIZM" },
    { val: [], name: "NATEN", ix: "XUTUM,XTUMY,XYLDZ,XUSRD,XUGRA,XUHIZ,XELKT,XSANK" },
    { val: [], name: "NETAS", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XSRDK,XUSRD,XUTEK,XTKJS,XBLSM,XSIST" },
    { val: [], name: "NIBAS", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XTAST" },
    { val: [], name: "NTGAZ", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUHIZ,XELKT,XSIST" },
    { val: [], name: "NTHOL", ix: "XUTUM,XTUMY,XYLDZ,XUGRA,XUMAL,XHOLD,XSIST" },
    { val: [], name: "NUGYO", ix: "XUTUM,XTUMY,XBANA,XKTUM,XK100,XUMAL,XGMYO" },
    { val: [], name: "NUHCM", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XKTMT,XUSIN,XTAST,XSKOC" },
    { val: [], name: "OBASE", ix: "XUTUM,XTUMY,XBANA,XHARZ,XTMTU,XUTEK,XTKJS,XBLSM,XSIST" },
    { val: [], name: "ODAS", ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUHIZ,XELKT" },
    { val: [], name: "OFSYM", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUSIN,XGIDA,XSANK" },
    { val: [], name: "ONCSM", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKOBI,XKTUM,XUSIN,XKMYA,XSANK" },
    { val: [], name: "ORCAY", ix: "XUTUM,XTUMY,XBANA,XHARZ,XKOBI,XUSIN,XGIDA" },
    { val: [], name: "ORGE", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUGRA,XUHIZ,XINSA,XSIST" },
    { val: [], name: "OSMEN", ix: "XUTUM,XTUMY,XBANA,XTMTU,XUGRA,XUMAL,XAKUR" },
    { val: [], name: "OSTIM", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XHOLD,XSANK" },
    { val: [], name: "OTKAR", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XSRDK,XKTMT,XKURY,XUSRD,XSD25,XTM25S,XUSIN,XMESY" },
    { val: [], name: "OYAKC", ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUSIN,XTAST,XSANK" },
    { val: [], name: "OYAYO", ix: "XYORT" },
    { val: [], name: "OYLUM", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XGIDA,XSKAY" },
    { val: [], name: "OYYAT", ix: "XUTUM,XTUMY,XYLDZ,XUMAL,XAKUR" },
    { val: [], name: "OZGYO", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUGRA,XUMAL,XGMYO" },
    { val: [], name: "OZKGY", ix: "XUTUM,XTUMY,XYLDZ,XUMAL,XGMYO" },
    { val: [], name: "OZRDN", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XKMYA,XSKOC" },
    { val: [], name: "OZSUB", ix: "XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUSIN,XSIZM" },
    { val: [], name: "PAGYO", ix: "XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUMAL,XGMYO" },
    { val: [], name: "PAMEL", ix: "XUTUM,XTUMY,XYLDZ,XUHIZ,XELKT" },
    { val: [], name: "PAPIL", ix: "XUTUM,XTUMY,XBANA,XTMTU,XUGRA,XUTEK,XTKJS,XBLSM,XSANK" },
    { val: [], name: "PARSN", ix: "XUTUM,XTUMY,XYLDZ,XUSIN,XMESY,XSIST" },
    { val: [], name: "PASEU", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUHIZ,XULAS,XSANK" },
    { val: [], name: "PCILT", ix: "XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUHIZ,XSIST" },
    { val: [], name: "PEGYO", ix: "XUTUM,XTUMY,XBANA,XUMAL,XGMYO" },
    { val: [], name: "PEKGY", ix: "XUTUM,XTUMY,XBANA,XKTUM,XK100,XUMAL,XGMYO" },
    { val: [], name: "PENGD", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XGIDA,XSBUR" },
    { val: [], name: "PENTA", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XUTEK,XTKJS,XBLSM,XSIST" },
    { val: [], name: "PETKM", ix: "XU100,XU050,XU030,X10XB,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XUSIN,XKMYA,XSIZM" },
    { val: [], name: "PETUN", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XKTMT,XKURY,XUSIN,XGIDA,XSIZM" },
    { val: [], name: "PGSUS", ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XKURY,XUSRD,XSD25,XUHIZ,XULAS,XSIST" },
    { val: [], name: "PINSU", ix: "XUTUM,XTUMY,XBANA,XKURY,XUSIN,XGIDA,XSIZM" },
    { val: [], name: "PKART", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUTEK,XTKJS,XBLSM,XSIST" },
    { val: [], name: "PKENT", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XTRZM,XSANT" },
    { val: [], name: "PLTUR", ix: "XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUHIZ,XSIST" },
    { val: [], name: "PNLSN", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XMANA,XSANK" },
    { val: [], name: "PNSUT", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XSRDK,XKTMT,XKURY,XUSRD,XUSIN,XGIDA,XSIZM" },
    { val: [], name: "POLHO", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XSRDK,XKTMT,XUSRD,XUMAL,XHOLD,XSKOC" },
    { val: [], name: "POLTK", ix: "XUTUM,XTUMY,XBANA,XKOBI,XTMTU,XKTUM,XKTMT,XUSIN,XKMYA,XSIST" },
    { val: [], name: "PRDGS", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUMAL,XHOLD" },
    { val: [], name: "PRKAB", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XKTMT,XKURY,XUSIN,XMESY,XSBUR" },
    { val: [], name: "PRKME", ix: "XUTUM,XTUMY,XYLDZ,XKOBI,XKURY,XUSIN,XMADN" },
    { val: [], name: "PRZMA", ix: "XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XKAGT,XSIST" },
    { val: [], name: "PSDTC", ix: "XUTUM,XTUMY,XBANA,XTMTU,XUHIZ,XTCRT,XSIZM" },
    { val: [], name: "PSGYO", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XKTUM,XK100,XK050,XUMAL,XGMYO" },
    { val: [], name: "QUAGR", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSIN,XTAST,XSAYD" },
    { val: [], name: "RALYH", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XHOLD,XSANK" },
    { val: [], name: "RAYSG", ix: "XUTUM,XTUMY,XBANA,XUMAL,XSGRT" },
    { val: [], name: "REEDR", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUTEK,XTKJS,XBLSM" },
    { val: [], name: "RNPOL", ix: "XUTUM,XTUMY,XBANA,XHARZ,XKOBI,XKTUM,XUGRA,XUSIN,XKMYA,XSKAY" },
    { val: [], name: "RODRG", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XTEKS,XSIST" },
    { val: [], name: "RTALB", ix: "XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XKMYA,XSKOC" },
    { val: [], name: "RUBNS", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XTEKS" },
    { val: [], name: "RYGYO", ix: "XUTUM,XTUMY,XYLDZ,XUMAL,XGMYO" },
    { val: [], name: "RYSAS", ix: "XUTUM,XTUMY,XYLDZ,XUHIZ,XULAS,XSIST" },
    { val: [], name: "SAFKR", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XMESY,XSIZM" },
    { val: [], name: "SAHOL", ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XUSRD,XSD25,XTM25S,XUGRA,XUMAL,XHOLD,XSIST" },
    { val: [], name: "SAMAT", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XKAGT,XSANK" },
    { val: [], name: "SANEL", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XINSA,XSIST" },
    { val: [], name: "SANFM", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XKMYA,XSTKR" },
    { val: [], name: "SANKO", ix: "XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUHIZ,XTCRT" },
    { val: [], name: "SARKY", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XUSIN,XMANA,XSKOC" },
    { val: [], name: "SASA", ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUSIN,XKMYA,XSADA" },
    { val: [], name: "SAYAS", ix: "XUTUM,XTUMY,XYLDZ,XKOBI,XKTUM,XUGRA,XUSIN,XMESY,XSIZM" },
    { val: [], name: "SDTTR", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUTEK,XTKJS,XSANK" },
    { val: [], name: "SEGYO", ix: "XUTUM,XTUMY,XBANA,XHARZ,XUMAL,XGMYO" },
    { val: [], name: "SEKFK", ix: "XUTUM,XTUMY,XBANA,XKURY,XUGRA,XUMAL,XFINK" },
    { val: [], name: "SEKUR", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XKMYA" },
    { val: [], name: "SELEC", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XKTUM,XK100,XKTMT,XUHIZ,XTCRT,XSIST" },
    { val: [], name: "SELGD", ix: "XUTUM,XTUMY,XKOBI,XKTUM,XUSIN,XGIDA,XSAYD" },
    { val: [], name: "SELVA", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XGIDA,XSKON" },
    { val: [], name: "SEYKM", ix: "XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XKMYA,XSMNS" },
    { val: [], name: "SILVR", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XMESY" },
    { val: [], name: "SISE", ix: "XU100,XU050,XU030,X10XB,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XKURY,XUSRD,XSD25,XTM25S,XUGRA,XUMAL,XHOLD,XSIST" },
    { val: [], name: "SKBNK", ix: "XU100,XLBNK,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XUMAL,XBANK" },
    { val: [], name: "SKTAS", ix: "XUTUM,XTUMY,XBANA,XUSIN,XTEKS,XSAYD" },
    { val: [], name: "SMART", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUTEK,XTKJS,XBLSM,XSIST" },
    { val: [], name: "SMRTG", ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XHARZ,XKTUM,XK100,XK050,XK030,XUHIZ,XELKT,XSKOC" },
    { val: [], name: "SNGYO", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUMAL,XGMYO" },
    { val: [], name: "SNICA", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUSIN,XMESY,XSMNS" },
    { val: [], name: "SOKE", ix: "XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XK100,XUSIN,XGIDA,XSAYD" },
    { val: [], name: "SOKM", ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XUSRD,XUHIZ,XTCRT" },
    { val: [], name: "SONME", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XSBUR" },
    { val: [], name: "SRVGY", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUMAL,XGMYO" },
    { val: [], name: "SUNTK", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XTMTU,XKTUM,XKTMT,XKURY,XUSIN,XTEKS,XSIZM" },
    { val: [], name: "SUWEN", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUHIZ,XTCRT" },
    { val: [], name: "TARKM", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUSIN,XKMYA,XSMNS" },
    { val: [], name: "TATEN", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUHIZ,XELKT,XSBAL" },
    { val: [], name: "TATGD", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKURY,XUSRD,XUSIN,XGIDA,XSBUR" },
    { val: [], name: "TAVHL", ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XKURY,XUSRD,XUMAL,XHOLD,XSIST" },
    { val: [], name: "TCELL", ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XUSRD,XSD25,XTM25S,XUGRA,XUHIZ,XILTM,XSIST" },
    { val: [], name: "TDGYO", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XGMYO" },
    { val: [], name: "TEKTU", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XTRZM,XSANT" },
    { val: [], name: "TERA", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUMAL,XAKUR" },
    { val: [], name: "TETMT", ix: "XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XGIDA,XSANK" },
    { val: [], name: "TEZOL", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUSIN,XKAGT,XSIZM" },
    { val: [], name: "TGSAS", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUGRA,XUHIZ,XTCRT,XSIST" },
    { val: [], name: "THYAO", ix: "XU100,XU050,XU030,X10XB,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XUSRD,XSD25,XUGRA,XUHIZ,XULAS,XSIST" },
    { val: [], name: "TKFEN", ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XUSRD,XSD25,XUMAL,XHOLD,XSIST" },
    { val: [], name: "TKNSA", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XK050,XUHIZ,XTCRT" },
    { val: [], name: "TLMAN", ix: "XUTUM,XTUMY,XBANA,XTMTU,XUHIZ,XULAS" },
    { val: [], name: "TMPOL", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XKMYA,XSKAY" },
    { val: [], name: "TMSN", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XK050,XUSIN,XMESY,XSKON" },
    { val: [], name: "TNZTP", ix: "XUTUM,XTUMY,XBANA,XHARZ,XUHIZ,XSIZM" },
    { val: [], name: "TOASO", ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XKURY,XUSRD,XSD25,XTM25S,XUSIN,XMESY,XSBUR" },
    { val: [], name: "TRCAS", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XKURY,XUMAL,XHOLD,XSIST" },
    { val: [], name: "TRGYO", ix: "XUTUM,XTUMY,XYLDZ,XUMAL,XGMYO" },
    { val: [], name: "TRILC", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XKMYA,XSANK" },
    { val: [], name: "TSGYO", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUMAL,XGMYO" },
    { val: [], name: "TSKB", ix: "XU100,XU050,XLBNK,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XSD25,XUMAL,XBANK" },
    { val: [], name: "TSPOR", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XSPOR" },
    { val: [], name: "TTKOM", ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XKURY,XUSRD,XTM25S,XUHIZ,XILTM,XSANK" },
    { val: [], name: "TTRAK", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XKURY,XUSRD,XSD25,XTM25S,XUSIN,XMESY,XSANK" },
    { val: [], name: "TUCLK", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XMANA,XSIST" },
    { val: [], name: "TUKAS", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XK050,XUSIN,XGIDA,XSIZM" },
    { val: [], name: "TUPRS", ix: "XU100,XU050,XU030,X10XB,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XKURY,XUSRD,XUSIN,XKMYA,XSKOC" },
    { val: [], name: "TUREX", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUHIZ,XULAS,XSIST" },
    { val: [], name: "TURGG", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XINSA,XSIST" },
    { val: [], name: "TURSG", ix: "XUTUM,XTUMY,XYLDZ,XKURY,XUGRA,XUMAL,XSGRT" },
    { val: [], name: "UFUK", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST" },
    { val: [], name: "ULAS", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XTRZM,XSANK" },
    { val: [], name: "ULKER", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUSRD,XUSIN,XGIDA,XSANK" },
    { val: [], name: "ULUFA", ix: "XUTUM,XTUMY,XBANA,XHARZ,XUMAL,XFINK" },
    { val: [], name: "ULUSE", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XMESY,XSANK" },
    { val: [], name: "ULUUN", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XK050,XUSIN,XGIDA" },
    { val: [], name: "UNLU", ix: "XUTUM,XTUMY,XYLDZ,XUMAL,XHOLD,XSIST" },
    { val: [], name: "USAK", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XTAST" },
    { val: [], name: "VAKBN", ix: "XU100,XU050,XLBNK,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XUGRA,XUMAL,XBANK" },
    { val: [], name: "VAKFN", ix: "XUTUM,XTUMY,XBANA,XUMAL,XFINK" },
    { val: [], name: "VAKKO", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUHIZ,XTCRT" },
    { val: [], name: "VANGD", ix: "XUTUM,XTUMY,XKOBI,XUSIN,XGIDA" },
    { val: [], name: "VBTYZ", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUTEK,XTKJS,XBLSM,XSIST" },
    { val: [], name: "VERTU", ix: "XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUGRA,XUMAL,XHOLD" },
    { val: [], name: "VERUS", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XKTMT,XUGRA,XUMAL,XHOLD,XSIST" },
    { val: [], name: "VESBE", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XUSRD,XTM25S,XUSIN,XMESY,XSMNS" },
    { val: [], name: "VESTL", ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XSD25,XUSIN,XMESY,XSMNS" },
    { val: [], name: "VKFYO", ix: "XYORT" },
    { val: [], name: "VKGYO", ix: "XUTUM,XTUMY,XYLDZ,XKURY,XUMAL,XGMYO" },
    { val: [], name: "VKING", ix: "XUTUM,XTUMY,XBANA,XUSIN,XKAGT,XSIZM" },
    { val: [], name: "YAPRK", ix: "XUTUM,XTUMY,XBANA,XKOBI,XTMTU,XKTUM,XKTMT,XUSIN,XSBAL" },
    { val: [], name: "YATAS", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XKTMT,XUSIN,XTEKS,XSKAY" },
    { val: [], name: "YAYLA", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XINSA,XSANK" },
    { val: [], name: "YEOTK", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUHIZ,XSIST" },
    { val: [], name: "YESIL", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST" },
    { val: [], name: "YGGYO", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XUMAL,XGMYO" },
    { val: [], name: "YGYO", ix: "XUTUM,XTUMY,XBANA,XUMAL,XGMYO" },
    { val: [], name: "YKBNK", ix: "XU100,XU050,XU030,XLBNK,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XKURY,XUSRD,XUMAL,XBANK" },
    { val: [], name: "YKSLN", ix: "XUTUM,XTUMY,XYLDZ,XUSIN,XMANA,XSIST" },
    { val: [], name: "YUNSA", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XKTMT,XKURY,XUSIN,XTEKS,XSTKR" },
    { val: [], name: "YYAPI", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XINSA,XSIST" },
    { val: [], name: "YYLGD", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XKTUM,XK100,XK050,XKURY,XUGRA,XUSIN,XGIDA" },
    { val: [], name: "ZEDUR", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XELKT,XSANK" },
    { val: [], name: "ZOREN", ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUSRD,XUHIZ,XELKT,XSBUR" },
    { val: [], name: "ZRGYO", ix: "XUTUM,XTUMY,XYLDZ,XUMAL,XGMYO" },


];

ImkbEndeksSenetler.forEach(element => {
    let list = element.ix.split(',');
    element.val = list.filter((x) => {
        return SentimentEndeksler.some(s => s.value === x);
    });
});



export default ImkbEndeksSenetler;