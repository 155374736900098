import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import klineInterval from "./params";

const DATA_COUNT = 50;
const COLLECTION_NAME = "NS_CORPORATE_SIGNALS";

interface IXUData {
  signals: { Basket: string[]; Outgoing: []; Incoming: []; Index: number };
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  signals: [] as any,
  requested: false,
  loaded: false,
};

export function loadSignalCorporate(firebase: any) {
console.log(`${COLLECTION_NAME}${klineInterval}`)
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.SignalCorporate.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.CORPORATE_SIGNALS,
      payload: { requested: true, loaded: false, sentiment: [] },
    });
    let ix = 0;
    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}${klineInterval}`)
      .where("reverse", "==", 0)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        let data: Object[] = [];
        querySnapshot.forEach(function (doc: any) {

          data.push({
            key: ix++,
            name: "signal",
            Basket: doc.data().basket ?? [],
            Outgoing: doc.data().outgoing ?? [],
            Incoming: doc.data().incoming ?? [],
            Time: doc.data().time ?? 0,
            Date: moment(doc.data().dateTime.toDate())
              //.zone(0)
              .format("DD.MM.YY HH:mm"),
          });
        });

        dispatch({
          type: SENTIMENT_ACTION.CORPORATE_SIGNALS,
          payload: {
            signals: data ?? [],
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function SignalCorporate(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.CORPORATE_SIGNALS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const signalCorporateSelector = (state: any) => state.SignalCorporate;
