import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import ImkbEndeksSenetler from "../../../util/ImkbEndeksSenetler";
import { string } from "prop-types";
import { isProd } from "../../../util/Env";

const DATA_COUNT = 30;
const COLLECTION_NAME = "NS_LINES_INDEXES";

interface IXUData {
  linesIndexes: IData[];
  requested: boolean;
  loaded: boolean;
  selectedDate: Date | null;
}

const initialState: IXUData = {
  linesIndexes: [],
  requested: false,
  loaded: false,
  selectedDate: null
};

export function loadLinesIndexes(firebase: any, period: number, count: number, interval: string = "", historyDate: Date | null = null) {
  if (period < 0 || ![0, 10, 30, 99].includes(period)) {
    console.log("Invalid period for line 30. Period:", period);
    return async function thunk(dispatch: any, getState: any) {
      dispatch({
        type: SENTIMENT_ACTION.EMPTY_ACTION,
        payload: {},
      });
    };
  }
  return async function thunk(dispatch: any, getState: any) {
    historyDate = historyDate ?? new Date();
    const state = getState();
    if (state.LinesIndexes.requested && historyDate != null && historyDate == state.selectedDate) return;
    dispatch({
      type: SENTIMENT_ACTION.LINES_INDEXES,
      payload: { requested: true, loaded: false, sentiment: [] },
    });
    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}`)
      .orderBy("dateTime", "desc")
      .where("dateTime", "<=", historyDate)
      .limit(count)
      .get().then((querySnapshot: any) => {
        var linesIndexesData: Object[] = [];
        querySnapshot.forEach(function (doc: any) {
          linesIndexesData.push({
            name: "linesIndexes",
            Index: 1001,
            Symbol: doc.data().symbol,
            Sentiment: doc.data().sentiment,
            Order: doc.data().order,
            Group: doc.data().group,
            ODate: doc.data().order <= 5 && doc.data().openDate == ""
              ? moment.unix(doc.data().dateTime.seconds).format("DD.MM.yyyy")
              : doc.data().openDate,
            OPrice: doc.data().openPrice != "0" ? doc.data().openPrice : (doc.data().order <= 10 ? doc.data().price : ""),
            Price: doc.data().price,
            DailyChange: doc.data().dailyChange,
            Vbts: doc.data().vbts,
            Viop: doc.data().viop,
            PerDay: doc.data().perDay,
            SentimentList: doc.data().sentimentList,
            PriceList: doc.data().priceList,
            ChangeList: doc.data().changeList,
            ReChangeList: doc.data().changeList.slice().reverse(),
            dateTime: doc.data().dateTime.toDate(),
            Time: doc.data().time ?? 0,
            UpdateTime: moment
              .unix(doc.data().dateTime.seconds)
              .format("DD.MM.yy HH:mm"),
            LineDate: doc.data().dateTime.seconds,
            Date: moment
              .unix(doc.data().dateTime.seconds)
              .format("YYYY/MM/DD"),
            Indexes: [],
            OrderList: doc.data().changeList.map((x: number) => { return doc.data().order - x }),
            Today: moment.unix(doc.data().dateTime.seconds).format("DD.MM.yyyy")
          });
        });

        if (isProd()) {
          linesIndexesData = linesIndexesData.filter((x: any) => {
            return !(
              x.Symbol == "XU100"
              || x.Symbol == "XU050"
              || x.Symbol == "XU030"
              || x.Symbol == "XK100"
              || x.Symbol == "XYLDZ"
              || x.Symbol == "XKTUM")
          });
        }

        linesIndexesData = linesIndexesData.sort((a: any, b: any) => { return b.Sentiment - a.Sentiment })
        linesIndexesData.forEach(function (data: any, i) {
          data.Order = i + 1;
        });
        console.log(linesIndexesData);
        dispatch({
          type: SENTIMENT_ACTION.LINES_INDEXES,
          payload: {
            linesIndexes: linesIndexesData,
            loaded: true,
            requested: true,
            selectedDate: historyDate
          },
        });
      });
  };
}

export default function LinesIndexes(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.LINES_INDEXES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const linesIndexesSelector = (state: any) => state.LinesIndexes;
